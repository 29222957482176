@media print {
    tr {
        -webkit-print-color-adjust: exact; 
    }
    @page { margin: 0; }
    body { margin: 1.6cm; }
      
    #break-content {
        break-inside: avoid;
      }

    .pagebreak {
        page-break-before: always  !important;
    }
      
}


